import {
  BorderRadiusStyles,
  ButtonConfigurations,
  ButtonSizeOptions,
  ButtonTypes,
  GlobalThemeConfiguration,
  InputFieldsConfigurations,
  InputLabelOptions,
  NewColourPaletteType,
} from '../config.type';
import { BorderRadiusClasses, TypographyKeys } from './global-styling.enum';

export const defaultColourPalette: NewColourPaletteType = {
  main: {
    primary: '#00d8be',
    background: '#080808',
    surface: '#000b0c',
    borderColor: '#00302a',
  },
  typography: {
    onPrimary: '#000b0c',
    onPrimaryMuted: '#f5f5f5',
    onBackground: '#dfe1e3',
    onBackgroundMuted: '#c0c2c6',
    onSurface: '#dfe1e3',
    onSurfaceMuted: '#757575',
  },
  states: {
    active: '#44c856',
    hover: '#fd3636',
  },
  status: {
    error: '#FF2323',
    success: '#1EA97C',
    info: '#696CFF',
    warning: '#CC8925',
  },
};

export const defaultGlobalThemeGeneralConfig: Pick<GlobalThemeConfiguration, 'generalConfigurations'> = {
  generalConfigurations: {
    companyInfo: {
      name: 'Finxone',
      legalEntityName: 'Finxone Ltd',
      logo: '',
      logoTitle: '',
      logoFileName: '',
    },
    fontFamily: 'Mulish',
    palette: defaultColourPalette,
  },
};

export const defaultGlobalThemeTypographyConfig: Pick<GlobalThemeConfiguration, 'typography'> = {
  typography: {
    [TypographyKeys.HEADING1]: {
      'font-weight': '900',
      'font-size': '32',
      'text-decoration': 'none',
      'letter-spacing': '5',
      'line-height': '40',
      'margin-bottom': '20',
    },
    [TypographyKeys.HEADING2]: {
      'font-weight': '900',
      'font-size': '28',
      'text-decoration': 'none',
      'letter-spacing': '5',
      'line-height': '36',
      'margin-bottom': '18',
    },
    [TypographyKeys.HEADING3]: {
      'font-weight': '900',
      'font-size': '24',
      'text-decoration': 'none',
      'letter-spacing': '5',
      'line-height': '32',
      'margin-bottom': '16',
    },
    [TypographyKeys.HEADING4]: {
      'font-weight': '900',
      'font-size': '20',
      'text-decoration': 'none',
      'letter-spacing': '5',
      'line-height': '28',
      'margin-bottom': '14',
    },
    [TypographyKeys.HEADING5]: {
      'font-weight': '900',
      'font-size': '18',
      'text-decoration': 'none',
      'letter-spacing': '5',
      'line-height': '24',
      'margin-bottom': '12',
    },
    [TypographyKeys.HEADING6]: {
      'font-weight': '900',
      'font-size': '16',
      'text-decoration': 'none',
      'letter-spacing': '5',
      'line-height': '22',
      'margin-bottom': '10',
    },
    [TypographyKeys.SUBTITLE1]: {
      'font-weight': '700',
      'font-size': '16',
      'text-decoration': 'none',
      'letter-spacing': '0.5',
      'line-height': '22',
      'margin-bottom': '10',
    },
    [TypographyKeys.SUBTITLE2]: {
      'font-weight': '700',
      'font-size': '14',
      'text-decoration': 'none',
      'letter-spacing': '0.25',
      'line-height': '20',
      'margin-bottom': '8',
    },
    [TypographyKeys.BODY1]: {
      'font-weight': '500',
      'font-size': '16',
      'text-decoration': 'none',
      'letter-spacing': '0.5',
      'line-height': '24',
      'margin-bottom': '10',
    },
    [TypographyKeys.BODY2]: {
      'font-weight': '500',
      'font-size': '14',
      'text-decoration': 'none',
      'letter-spacing': '0.25',
      'line-height': '20',
      'margin-bottom': '8',
    },
    [TypographyKeys.BUTTON_TEXT]: {
      'font-weight': '600',
      'font-size': '14',
      'text-decoration': 'none',
      'letter-spacing': '1.25',
      'line-height': '16',
      'margin-bottom': '0',
    },
    [TypographyKeys.LABEL_TEXT]: {
      'font-weight': '400',
      'font-size': '12',
      'text-decoration': 'none',
      'letter-spacing': '0.4',
      'line-height': '16',
      'margin-bottom': '4',
    },
  },
};

export function generateDefaultButtonThemeConfigurations(type: ButtonTypes): Partial<ButtonConfigurations> {
  let defaultConfig: Partial<ButtonConfigurations> = {
    idle: {
      'background-color': '#00d8be',
      'border-color': '#ccc',
      textColor: {
        color: '#000b0c',
      },
    },
    active: {
      'background-color': '#00d8be',
      'border-color': '#bbb',
      textColor: {
        color: '#000b0c',
      },
    },
    hover: {
      'background-color': '#fd3636',
      'border-color': '#aaa',
      textColor: {
        color: '#000b0c',
      },
    },
  };
  if (type !== ButtonTypes.SECONDARY) {
    defaultConfig = {
      ...defaultConfig,
      baseStyles: {
        'justify-content': 'center',
        'align-items': 'center',
        'border-style': 'solid',
        'box-shadow': '0px 4px 6px rgba(0, 0, 0, 0.1)',
        'border-top-left-radius': '0px',
        'border-top-right-radius': '0px',
        'border-bottom-right-radius': '0px',
        'border-bottom-left-radius': '0px',
        'border-width-top': '1px',
        'border-width-bottom': '1px',
        'border-width-left': '1px',
        'border-width-right': '1px',
      },
      size: {
        [ButtonSizeOptions.SMALL]: {
          'padding-top': '0px',
          'padding-right': '0px',
          'padding-bottom': '0px',
          'padding-left': '0px',
          'margin-top': '0px',
          'margin-right': '0px',
          'margin-bottom': '0px',
          'margin-left': '0px',
          paddingWithIcon: {
            gap: '6px',
            'padding-right': '0px',
            'padding-left': '0px',
          },
        },
        [ButtonSizeOptions.LARGE]: {
          'padding-top': '0px',
          'padding-right': '0px',
          'padding-bottom': '0px',
          'padding-left': '0px',
          'margin-top': '0px',
          'margin-right': '0px',
          'margin-bottom': '0px',
          'margin-left': '0px',
          paddingWithIcon: {
            gap: '6px',
            'padding-right': '0px',
            'padding-left': '0px',
          },
        },
        [ButtonSizeOptions.FULL_WIDTH]: {
          'padding-top': '0px',
          'padding-right': '0px',
          'padding-bottom': '0px',
          'padding-left': '0px',
          'margin-top': '0px',
          'margin-right': '0px',
          'margin-bottom': '0px',
          'margin-left': '0px',
          paddingWithIcon: {
            gap: '6px',
            'padding-right': '0px',
            'padding-left': '0px',
          },
        },
      },
      borderRadiusClass: BorderRadiusClasses.RADIUS_ONE,
    };
  }
  if (type === ButtonTypes.MENU) {
    defaultConfig = {
      ...defaultConfig,
      iconConfigurations: {
        type: 'right',
        iconName: 'arrow',
        height: '18px',
        width: '18px',
        color: '#000b0c',
      },
    };
  }
  return defaultConfig;
}

export const defaultGlobalThemeRadiusItemConfig: BorderRadiusStyles = {
  'border-top-left-radius': '0',
  'border-top-right-radius': '0',
  'border-bottom-right-radius': '0',
  'border-bottom-left-radius': '0',
};

export const defaultInputFieldConfigs: InputFieldsConfigurations = {
  idle: {
    'background-color': '#00d8be',
    'border-color': '#ccc',
    textColor: {
      color: '#000b0c',
    },
    labelColor: {
      color: '#000b0c',
    },
  },
  active: {
    'background-color': '#00d8be',
    'border-color': '#ccc',
    textColor: {
      color: '#000b0c',
    },
    labelColor: {
      color: '#000b0c',
    },
  },
  labelConfig: {
    type: InputLabelOptions.STATIC,
    'align-items': 'center',
    'justify-content': 'start',
    'padding-top': '10px',
    'padding-right': '5px',
    'padding-bottom': '10px',
    'padding-left': '5px',
  },
  inputStyles: {
    'align-items': 'center',
    'justify-content': 'start',
    'padding-top': '8px',
    'padding-right': '4px',
    'padding-bottom': '8px',
    'padding-left': '4px',
    height: '40px',
    width: '100%',
    'border-top-left-radius': '5px',
    'border-top-right-radius': '5px',
    'border-bottom-right-radius': '5px',
    'border-bottom-left-radius': '5px',
    'border-width-top': '1px',
    'border-width-left': '1px',
    'border-width-bottom': '1px',
    'border-width-right': '1px',
    'box-shadow': '0px 4px 6px rgba(0, 0, 0, 0.1)',
    'border-style': 'solid',
  },
  textStyleClass: 'body-1',
  borderRadiusClass: BorderRadiusClasses.RADIUS_ONE,
};

export const defaultGlobalThemeRadiusConfig: {
  [key in Exclude<BorderRadiusClasses, BorderRadiusClasses.NONE>]: BorderRadiusStyles;
} = {
  [BorderRadiusClasses.RADIUS_ONE]: defaultGlobalThemeRadiusItemConfig,
  [BorderRadiusClasses.RADIUS_TWO]: defaultGlobalThemeRadiusItemConfig,
};

export const globalThemeConstant: GlobalThemeConfiguration = {
  themeName: 'Default',
  generalConfigurations: defaultGlobalThemeGeneralConfig.generalConfigurations,
  buttons: {
    [ButtonTypes.PRIMARY]: generateDefaultButtonThemeConfigurations(ButtonTypes.PRIMARY),
    [ButtonTypes.SECONDARY]: generateDefaultButtonThemeConfigurations(ButtonTypes.SECONDARY),
    [ButtonTypes.MENU]: generateDefaultButtonThemeConfigurations(ButtonTypes.MENU),
  },
  typography: defaultGlobalThemeTypographyConfig.typography,
  inputFields: defaultInputFieldConfigs,
  radius: defaultGlobalThemeRadiusConfig,
} as GlobalThemeConfiguration;

export const NewThemeVarsToExistingPaletteMap = new Map<string, string>([
  ['primary', 'palette-3'],
  ['background', 'palette-6'],
  ['surface', 'palette-5'],
  ['borderColor', 'palette-4'],
  ['onBackground', 'palette-0'],
  ['onBackgroundMuted', 'palette-1'],
  ['error', 'alert-0'],
  ['success', 'alert-1'],
  ['info', 'alert-2'],
  ['warning', 'alert-3'],
]);
